import React from "react";

const Footer = () => {
  return (
    <div className="w-screen h-[130px] border-t border-neutral">
      <div className="px-2 flex justify-between items-center w-full h-full">
        <div className="flex items-center">
          <h1 className="text-3xl sm:text-4xl sm:pl-5 font-bold pl-10">
            &lt;/JHartmann&gt;
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Footer;
